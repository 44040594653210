import { lazy } from "react";

export default [
  {
    exact: true,
    path: ["/:customerName/infeco/permission/statement/:id/"],
    component: lazy(() =>
      import(
        "src/views/arkadu/taxes/informal_economy/InformalPermissionStatement"
      )
    ),
  },
  {
    exact: true,
    path: ["/:customerName/infeco/:tabName", "/:customerName/infeco/"],
    component: lazy(() =>
      import("src/views/arkadu/taxes/informal_economy/InformalEconomyIndex")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/infeco/permission/:id/"],
    component: lazy(() =>
      import(
        "src/views/arkadu/taxes/informal_economy/InformalEconomyPermissionDetail"
      )
    ),
  },
];
