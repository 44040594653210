
import { Hidden } from "@mui/material";
import React, { useEffect, useState } from "react";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { useSelector } from "src/store";

const Logo = (props) => {
  const { adminMode, settingsLoaded } = useSelector((state) => state.settings);
  const [logoSrc, setLogoSrc] = useState("/static/arkadu_logo_wtop.png");
  const { currentCustomer } = useSelector((state) => state.customers);
  const isMountedRef = useIsMountedRef();
  const { dark } = props;
  const [logos, setLogos] = useState({
    // black: "/static/images/logo_black.png",
    // white: "/static/images/logo_white.png",
    black: window["logo_black"],
    white: window["logo_white"],
  });
  const [arkaduIcons, setArkaduIcons] = useState({
    black: "/static/images/arkadu_icon_light.png",
    white: "/static/images/arkadu_icon_dark.png",
  });
  useEffect(() => {
    if (currentCustomer && currentCustomer.theme_preferences) {
      // handleCustomerLogo();
    }
  }, [currentCustomer]);

  if (window.location.hostname.endsWith("arkadu.com")) {
    return (
      <>
        <Hidden lgDown>
          <img
            alt="Logo"
            src={logos[adminMode || dark ? "white" : "white"]}
            {...props}
            // height="28px"
            height="40px"
            // width="120px"
            style={{ height: "28px !important", width: "auto !important" }}
          />
        </Hidden>
        <Hidden mdUp>
          <img
            alt="Logo"
            src={arkaduIcons[adminMode || dark ? "white" : "white"]}
            {...props}
            height="40px"
            // width="120px"
            style={{ height: "28px !important", width: "auto !important" }}
          />
        </Hidden>
      </>
    );
  }
  return (
    <img
      alt="Logo"
      src={logos[adminMode || dark ? "white" : "white"]}
      {...props}
      // height="28px"
      // // width="120px"
      height="40px"
      style={{ height: "28px !important", width: "auto !important" }}
    />
  );
};

export default Logo;
