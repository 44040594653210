
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import { server } from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { setDevState } from "src/slices/nets_dev";
import { useDispatch, useSelector } from "src/store";

const DevLayout = ({ children }) => {
  // @ts-ignore
  const { currentCustomer } = useSelector((state) => state.customers);
  const { t } = useTranslation();
  const { api } = useAsyncRequest();

  // @ts-ignore
  const { requestOptions } = useSelector((state) => state.dev);
  const dispatch = useDispatch();
  const loadOptions = async () => {
    var res = await api(
      `${server}/api/v2/dev/request/options/`,
      {
        customer_id: currentCustomer.id,
      },
      {},
      null
    );
    if (res.data.res === 1) {
      dispatch(
        setDevState({
          requestOptions: {
            categories: res.data.data.category,
            priorities: res.data.data.priority,
            status: res.data.data.status,
            loaded: true,
          },
        })
      );
    }
  };
  useEffect(() => {
    if (currentCustomer && !requestOptions.loaded) {
      loadOptions();
    }
  }, [currentCustomer, requestOptions]);

  if (requestOptions.loaded === false) {
    return <LoadingScreen message={t("Cargando opciones")} />;
  }
  return <div>{children}</div>;
};

export default DevLayout;
