import { lazy } from "react";
import TaxAdminLayout from "src/views/arkadu/taxes/admin/AdminLayout";
export default [
  {
    exact: true,
    path: "/:customerName/taxes/load-license-from-image/",
    component: lazy(() =>
      import("src/views/arkadu/taxes/license/parts/LoadLicenseFromImage")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/taxes/license/:licenseId/amendment/:id"],
    component: lazy(() =>
      import("src/views/arkadu/taxes/license/parts/LicenseTaxAmendmentDetail")
    ),
  },
  {
    exact: true,
    path: [
      "/:customerName/taxes/license/:licenseId/:tabName",
      "/:customerName/taxes/license/:licenseId/",
    ],
    component: lazy(() =>
      import("src/views/arkadu/taxes/license/parts/LicenseDetailView")
    ),
  },
  {
    exact: true,
    path: "/:customerName/taxes/license/revision/:licenseId/",
    component: lazy(() =>
      import("src/views/arkadu/taxes/license/parts/LicenseRevision")
    ),
  },
  {
    exact: true,
    path: "/:customerName/taxes/declaration/:declarationId/",
    component: lazy(() => import("src/views/arkadu/taxes/DeclarationDetail")),
  },
  {
    exact: true,
    path: "/:customerName/taxes/retention/:retentionId/",
    component: lazy(() =>
      import("src/views/arkadu/taxes/TaxesRetentionDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/taxes/classifier/",
    component: lazy(() => import("src/views/arkadu/taxes/TaxesClassifier")),
  },
  {
    exact: true,
    path: ["/:customerName/taxes/penalty/detail/:id"],
    component: lazy(() =>
      import("src/views/arkadu/taxes/penalty/PenaltyDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/taxes/liquor/license/:id",
    component: lazy(() =>
      import("src/views/arkadu/taxes/liquor/LiquorLicenseDetail.js")
    ),
  },
  {
    path: "/:customerName/taxes/FixMigrating/",
    layout: TaxAdminLayout,
    routes: [
      {
        exact: true,
        path: "/:customerName/taxes/FixMigrating/",
        component: lazy(() =>
          import("src/views/arkadu/taxes/TaxesFixMigrating")
        ),
      },
    ],
  },
  {
    exact: true,
    path: ["/:customerName/taxes/:tabName/", "/:customerName/taxes/"],
    component: lazy(() => import("src/views/arkadu/taxes/TaxesIndex")),
  },
  {
    path: "/:customerName/taxes/dashboard/",
    layout: TaxAdminLayout,
    routes: [
      {
        exact: true,
        path: "/:customerName/taxes/dashboard/periods/",
        component: lazy(() =>
          import("src/views/arkadu/taxes/admin/period/PeriodReport")
        ),
      },
      {
        exact: true,
        path: "/:customerName/taxes/dashboard/",
        component: lazy(() =>
          import("src/views/reports/DashboardAlternativeView")
        ),
      },
    ],
  },
];
