import { Grid, Typography } from "@mui/material";
import React from "react";
import useTaxes from "src/hooks/useTaxes";
import { useSelector } from "src/store";
import ErrorView from "src/views/errors/ErrorView";

const InformalEconomyLayout = ({ children }) => {
  const { informal } = useTaxes();
  const { informalActivities } = useSelector((state) => state.taxes);
  const [thisState, setThisState] = React.useState({
    loaded: false,
  });
  const initialized = React.useRef(false);

  const loadActivities = async () => {
    await informal.activities();
    setThisState({ ...thisState, loaded: true });
  };

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadActivities();
    }
  }, []);

  if (!thisState.loaded) {
    return <InformalEconomyLoading />;
  }
  if (!informalActivities) {
    return (
      <ErrorView message="No se pudieron cargar las actividades de la economía popular" />
    );
  }
  return <div>{children}</div>;
};

const InformalEconomyLoading = ({ message }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      {/* play video /static/videos/coffee.mp4  in loop */}
      <video autoPlay loop muted style={{ width: "300px" }}>
        <source src="/static/videos/coffee.mp4" type="video/mp4" />
      </video>
      {message && <Typography>{message}</Typography>}
    </Grid>
  );
};
export { InformalEconomyLayout, InformalEconomyLoading };
export default InformalEconomyLayout;
