import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import LoadingScreen from "src/components/LoadingScreen";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import { AppProvider } from "./contexts/AppContext";
import account_routes from "./routes/account_routes";
import app_routes from "./routes/app_routes";
import audit_routes from "./routes/audit_routes";
import cadaster_routes from "./routes/cadaster_routes";
import corporate_routes from "./routes/corporate_routes";
import dev_routes from "./routes/dev_routes";
import environment_routes from "./routes/environment_routes";
import erp_routes from "./routes/erp_routes";
import eventads_routes from "./routes/eventads_routes";
import informal_economy_routes from "./routes/informal_economy_routes";
import notify_routes from "./routes/notify_routes";
import planning_routes from "./routes/planning_routes";
import poll_routes from "./routes/poll_routes";
import service_routes from "./routes/service_routes";
import socialresp_routes from "./routes/socialresp_routes";
import stamp_routes from "./routes/stamp_routes";
import stripe_routes from "./routes/stripe_routes";
import suppliers_routes from "./routes/suppliers_routes";
import taxes_routes from "./routes/taxes_routes";
import urbin_routes from "./routes/urbin_routes";
import ShopLayout from "./views/arkadu/accounts/ShopLayout";
import BankLayout from "./views/arkadu/bank/BankLayout";
import BlogIndex from "./views/arkadu/blog/BlogIndex";
import BlogLayout from "./views/arkadu/blog/BlogLayout";
import CadasterLayout from "./views/arkadu/cadaster/CadasterLayout";
import CorporateLayout from "./views/arkadu/corporate/CorporateLayout";
import CustomerLayout from "./views/arkadu/customer/CustomerLayout";
import DevLayout from "./views/arkadu/dev/DevLayout";
import EnvironmentLayout from "./views/arkadu/environment/EnvironmentLayout";
import EventadsLayout from "./views/arkadu/eventads/EventadsLayout";
import GlobalLayout from "./views/arkadu/global/GlobalLayout";
import VehicleMotorLayout from "./views/arkadu/motor/VehicleMotorLayout";
import PlanningLayout from "./views/arkadu/planning/PlanningLayout";
import PollLayout from "./views/arkadu/poll/PollLayout";
import ServicesLayout from "./views/arkadu/service/NetsServiceLayout";
import StampLayout from "./views/arkadu/stamp/StampLayout";
import SuppliersLayout from "./views/arkadu/suppliers/SuppliersLayout";
import InformalEconomyLayout from "./views/arkadu/taxes/informal_economy/InformalEconomyLayout";
import TaxesLayout from "./views/arkadu/taxes/TaxesLayout";
import UrbinLayout from "./views/arkadu/urbin/UrbinLayout";
import UserLayout from "./views/arkadu/user/UserLayout";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen message={"routes"} />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <AppProvider>
                      <Component {...props} />
                    </AppProvider>
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ["/:customerName/login", "/login"],
    component: lazy(() => import("src/views/auth/LoginView/index")),
  },
  {
    exact: true,
    path: "/email",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/mail/MailView/index")),
  },
  {
    exact: true,
    path: "/certificate/validation/:id/",
    component: lazy(() => import("src/views/arkadu/certificate/index")),
  },
  {
    exact: true,
    path: [
      "/certificate/vehicle/taxes/",
      "/certificate/vehicle/taxes/:serialID",
    ],
    component: lazy(() =>
      import("src/views/arkadu/certificate/VehicleCertificates")
    ),
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: app_routes,
  },
  {
    path: "/subscriptions",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: stripe_routes,
  },
  {
    path: "*",
    routes: [
      {
        path: "/:customerName/monitor/*",
        layout: MainLayout,
        guard: AuthGuard,
        routes: [
          {
            exact: true,
            path: "/:customerName/monitor/appointments/",
            component: lazy(() =>
              import(
                "src/views/arkadu/corporate/appointments/AppointmentsCallMonitor"
              )
            ),
          },
        ],
      },
      {
        layout: MainLayout,
        guard: AuthGuard,
        exact: true,
        path: [
          "/:customerName/AdminDashboard/:tabName",
          "/:customerName/AdminDashboard",
        ],
        component: lazy(() =>
          import("src/views/arkadu/dashboard/AdminDashBoard")
        ),
      },
      {
        exact: true,
        layout: BlogLayout,
        path: "/:customerName/blog/:id",
        component: lazy(() => import("src/views/arkadu/blog/BlogPostDetail")),
      },
      {
        path: "/:customerName/",
        layout: DashboardLayout,
        routes: [
          // {
          //   path: '/Guaicaipuro',
          //   component: lazy(() => import('src/views/arkadu/Maintenance'))
          // },
          // {
          //   path: "/:customerName/geo/GMaps/",
          //   guard: AuthGuard,
          //   component: lazy(() =>
          //     import("src/views/arkadu/geo/GoogleMapsAddress")
          //   ),
          // },
          {
            guard: AuthGuard,
            path: "/:customerName/audit/*",
            routes: audit_routes,
          },
          {
            path: "/:customerName/login",
            guard: GuestGuard,
            component: lazy(() => import("src/views/auth/LoginView/index")),
          },
          {
            path: "/:customerName/bank/",
            layout: BankLayout,
            guard: AuthGuard,
            routes: [
              {
                exact: true,
                path: "/:customerName/bank/transfer/result/:id/",
                component: lazy(() =>
                  import("src/views/arkadu/bank/TransferResult")
                ),
              },
              {
                exact: true,
                path: "/:customerName/bank/transfer/same/",
                component: lazy(() =>
                  import("src/views/arkadu/bank/SameUserTransfer")
                ),
              },
              {
                exact: true,
                path: "/:customerName/bank/:serviceId/products/",
                component: lazy(() =>
                  import("src/views/arkadu/bank/BankProducts")
                ),
              },
              {
                exact: true,
                path: [
                  "/:customerName/bank/admin/:serviceId/:environmentId/",
                  "/:customerName/bank/admin/:serviceId/",
                ],
                component: lazy(() =>
                  import("src/views/arkadu/bank/BankServiceAdmin")
                ),
              },
              {
                exact: true,
                path: "/:customerName/bank/",
                component: lazy(() =>
                  import("src/views/arkadu/bank/BankIndex")
                ),
              },
            ],
          },
          {
            path: "/:customerName/userRegistration/",
            guar: AuthGuard,
            component: lazy(() =>
              import("src/views/arkadu/user/SimpleRegistrationForm")
            ),
          },

          {
            path: "/:customerName/taxes/*",
            layout: TaxesLayout,
            guard: AuthGuard,
            routes: taxes_routes,
          },
          {
            path: "/:customerName/infeco/",
            layout: InformalEconomyLayout,
            guard: AuthGuard,
            routes: informal_economy_routes,
          },
          {
            path: "/:customerName/service/",
            guard: AuthGuard,
            layout: ServicesLayout,
            routes: service_routes,
          },
          // {
          //   path: "/:customerName/urban_cleaning/",
          //   guard: AuthGuard,
          //   layout: UrbanCleaningLayout,
          //   routes: uc_routes,
          // },
          {
            path: "/:customerName/system/dev/",
            guard: AuthGuard,
            layout: DevLayout,
            routes: dev_routes,
          },
          {
            path: "/:customerName/notify/",
            guard: AuthGuard,
            // layout: UrbanCleaningLayout,
            routes: notify_routes,
          },
          {
            path: "/:customerName/global/",
            layout: GlobalLayout,
            guard: AuthGuard,
            routes: [
              {
                exact: true,

                path: ["/:customerName/global/"],
                component: lazy(() =>
                  import("src/views/arkadu/global/GlobalIndex")
                ),
              },
            ],
          },
          {
            path: "/:customerName/customer/",
            layout: CustomerLayout,
            guard: AuthGuard,
            routes: [
              {
                exact: true,

                path: ["/:customerName/customer/"],
                component: lazy(() =>
                  import("src/views/arkadu/customer/CustomerIndex")
                ),
              },
            ],
          },
          {
            path: "/:customerName/erp",
            guard: AuthGuard,
            routes: erp_routes,
          },
          {
            path: "/:customerName/socialresp",
            guard: AuthGuard,
            routes: socialresp_routes,
          },
          {
            path: "/:customerName/eventads",
            guard: AuthGuard,
            layout: EventadsLayout,
            routes: eventads_routes,
          },
          {
            path: "/:customerName/corporate/",
            guard: AuthGuard,
            layout: CorporateLayout,
            routes: corporate_routes,
          },
          {
            exact: true,
            path: "/:customerName/config",
            component: lazy(() =>
              import("src/views/arkadu/customer/forms/CustomerSettingsForm")
            ),
          },

          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/",
            component: lazy(() =>
              import("src/views/arkadu/module/CustomerIndex")
            ),
          },

          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/posts/new/",
            component: lazy(() =>
              import("src/views/arkadu/module/Timeline/NewPost")
            ),
          },

          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/taxes/declaration/:declarationId/",
            component: lazy(() =>
              import("src/views/arkadu/taxes/DeclarationDetail")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/taxes/license/:licenseId",
            component: lazy(() =>
              import("src/views/arkadu/taxes/license/parts/LicenseDetailView")
            ),
          },

          // {
          //   exact: true,
          //   guard: AuthGuard,
          //   path: [
          //     '/:customerName/taxes/',
          //     '/:customerName/taxes/:tabName/:periodId',
          //     // '/:customerName/taxes/periods/',
          //     // '/:customerName/taxes/reports/',
          //     // '/:customerName/taxes/classifier/',
          //     '/:customerName/taxes/:tabName/'
          //   ],
          //   component: lazy(() =>
          //     import('src/views/arkadu/taxes/TaxesDashboard')
          //   )
          // },
          // {
          //   exact: true,
          //   guard: AuthGuard,
          //   path: [
          //     '/:customerName/taxes/license/:licenseId/declaration/:declarationId',
          //     '/:customerName/taxes/declaration/:id/certificate/',
          //     '/:customerName/taxes/declaration/:id/',
          //     '/:customerName/taxes/license/:id',
          //     '/:customerName/taxes/:currentTab/:id',
          //     '/:customerName/taxes/:currentTab/',
          //     '/:customerName/taxes/'
          //   ],
          //   component: lazy(() => import('src/views/arkadu/taxes/index'))
          // },

          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/taxes/declaration/:id/certificate/",
            component: lazy(() =>
              import("src/views/arkadu/taxes/CertificateDetailsView/index")
            ),
          },
          {
            path: "/:customerName/accounts",
            guard: AuthGuard,
            layout: ShopLayout,
            routes: account_routes,
          },
          {
            path: "/:customerName/poll",
            guard: AuthGuard,
            layout: PollLayout,
            routes: poll_routes,
          },
          {
            path: "/:customerName/cadaster/",
            guard: AuthGuard,
            layout: CadasterLayout,
            routes: cadaster_routes,
          },
          {
            path: "/:customerName/urbin/",
            layout: UrbinLayout,
            guard: AuthGuard,
            routes: urbin_routes,
          },
          {
            path: "/:customerName/environment/",
            guard: AuthGuard,
            layout: EnvironmentLayout,
            routes: environment_routes,
          },
          {
            guard: AuthGuard,
            layout: StampLayout,
            path: ["/:customerName/stamp/"],
            routes: stamp_routes,
          },
          {
            path: "/:customerName/suppliers/",
            guard: AuthGuard,
            layout: SuppliersLayout,
            routes: suppliers_routes,
          },
          {
            path: "/:customerName/planning/",
            guard: AuthGuard,
            layout: PlanningLayout,
            routes: planning_routes,
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/support/ticket/new/",
            component: lazy(() =>
              import("src/views/arkadu/support/NewTicketForm")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/support/ticket/detail/:id",
            component: lazy(() =>
              import("src/views/arkadu/support/TicketDetail")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/support/",
            component: lazy(() =>
              import("src/views/arkadu/support/SupportIndex")
            ),
          },

          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/advertise/",
            component: lazy(() => import("src/views/arkadu/advertise/index")),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/civil/",
            component: lazy(() => import("src/views/arkadu/civil/index")),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/urban/",
            component: lazy(() => import("src/views/arkadu/urban/index")),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/corporateAdmin/users/",
            component: lazy(() =>
              import("src/views/arkadu/admin/corporate/parts/StaffAdmin")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/corporateAdmin/",
            component: lazy(() =>
              import("src/views/arkadu/admin/corporate/CorporateIndexView")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            layout: VehicleMotorLayout,
            path: "/:customerName/motor/fix/",
            component: lazy(() =>
              import("src/views/arkadu/motor/FixMotorIndex")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            layout: VehicleMotorLayout,
            path: [
              "/:customerName/motor/inscription",
              "/:customerName/motor/newVehicle",

              "/:customerName/motor/:tabName",
              "/:customerName/motor/",
            ],
            component: lazy(() => import("src/views/arkadu/motor/index")),
          },
          {
            exact: true,
            guard: AuthGuard,
            layout: VehicleMotorLayout,
            path: [
              "/:customerName/motor/vehicle/statement/:vehicleId",
              "/:customerName/motor/vehicle/:inscriptionId",
            ],
            component: lazy(() =>
              import("src/views/arkadu/motor/VehicleMotorDetail")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/translate/:id",
            component: lazy(() =>
              import("src/views/arkadu/trans/TransSetView")
            ),
          },
          {
            exact: true,
            guard: AuthGuard,
            path: "/:customerName/translate/",
            component: lazy(() => import("src/views/arkadu/trans/index")),
          },

          {
            path: "/:customerName/admin/*",
            routes: [
              {
                exact: true,
                path: "/:customerName/admin/taxes/declaration/:id",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/taxes/DeclarationDetail")
                ),
              },

              {
                exact: true,
                path: "/:customerName/admin/taxes/period/add/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/taxes/admin/period/AddPeriod")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/taxes/period/:id/addFee/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/taxes/admin/period/AddPeriodFee")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/taxes/period/:id/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/taxes/admin/period/PeriodView")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/taxes/:tabName",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/taxes/admin/index")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/taxes/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/taxes/admin/index")
                ),
              },

              {
                exact: true,
                guard: AuthGuard,
                path: [
                  "/:customerName/admin/support/:userId/ticket/:ticketId",
                  "/:customerName/admin/support/:userId",
                  "/:customerName/admin/support/",
                ],
                component: lazy(() =>
                  import("src/views/arkadu/support/SupportIndex")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/accounts/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/accounts/AddFundAdmin")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/user/registration/",
                guard: AuthGuard,
                layout: UserLayout,
                component: lazy(() =>
                  import("src/views/arkadu/user/SimpleRegistrationForm")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/user/:userId/deposit/",
                guard: AuthGuard,
                component: lazy(() =>
                  import(
                    "src/views/arkadu/user/admin/components/AddFundAdminStepper"
                  )
                ),
              },
              {
                exact: true,
                path: ["/:customerName/admin/user/:userId/notification/"],
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/notify/NewNotifyMessage")
                ),
              },

              {
                exact: true,
                path: [
                  "/:customerName/admin/user/:userId/:tabName",
                  "/:customerName/admin/user/:userId",
                ],
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/user/admin/UserAdmin")
                ),
              },
              {
                exact: true,
                path: "/:customerName/admin/cadaster/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/arkadu/cadaster/admin/index")
                ),
              },

              {
                exact: true,
                path: "/:customerName/admin/",
                guard: AuthGuard,
                component: lazy(() =>
                  import("src/views/reports/DashboardAlternativeView/index")
                ),
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        exact: true,
        layout: BlogLayout,
        path: "/",
        component: BlogIndex,
      },

      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
