import { UploadRounded } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import bytesToSize from "src/utils/bytesToSize";

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(3),
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const FilesDropzone = ({
  enabled,
  className,
  onUpload,
  many,
  message,
  accept,
  uploadOnSelect,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setError] = useState("");
  const handleDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      setError(t("No ha seleccionado ningún archivo válido."));
      return;
    }
    setError("");
    if (uploadOnSelect) {
      onUpload(acceptedFiles);
      return;
    }

    if (many) {
      setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
    } else {
      setFiles([acceptedFiles[0]]);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: accept ? accept : null,
    maxFiles: many ? 0 : 1,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {files.length > 0 ? (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: "h5" }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="More options">
                    <IconButton edge="end" size="large">
                      <MoreIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small">
              {t("Eliminar todos")}
            </Button>
            {uploadOnSelect ? null : (
              <Button
                disabled={Boolean(!files || files.length === 0 || !enabled)}
                onClick={() => {
                  onUpload(files);
                }}
                color="secondary"
                size="small"
                variant="contained"
              >
                {t("Cargar Archivos")}
              </Button>
            )}
          </div>
        </>
      ) : (
        <div
          className={clsx({
            [classes.dropZone]: true,
            [classes.dragActive]: isDragActive,
          })}
          {...getRootProps()}
          style={{
            padding: {
              xs: "12px",
              md: "48px",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <input {...getInputProps()} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <div style={{ display: "inline-block" }}>
                <img
                  alt="Carga Archivos"
                  className={classes.image}
                  style={{ width: isSmall ? "32px" : "130px" }}
                  src="/static/images/undraw_add_file2_gvbb.svg"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <div style={{ display: "inline-block" }}>
                <Button
                  size={isSmall ? "small" : "large"}
                  variant="contained"
                  startIcon={<UploadRounded />}
                >
                  {message ? message : t("Cargar Archivos")}
                </Button>
                {/* {message && (
            <Box mt={2}>
              <Typography color="secondary" variant="h4">
                {message}
              </Typography>
            </Box>
          )} */}
                {!isSmall && (
                  <Box mt={2}>
                    <Typography color="textPrimary" variant="body1">
                      {t(
                        "Arrastre y suelte los archivos aquí, o haga clic en "
                      )}{" "}
                      <Link underline="always">{t("explorar")}</Link>{" "}
                      {t("para buscarlos en su dispositivo.")}
                    </Typography>
                  </Box>
                )}
                {error && <FormHelperText error>{error}</FormHelperText>}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool,
  many: PropTypes.bool,
  message: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  uploadOnSelect: PropTypes.bool,
  accept: PropTypes.string,
};

FilesDropzone.defaultProps = {
  enabled: true,
  many: true,
};
export default FilesDropzone;
