import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { server } from "src/coreConfig";
import { setMotorItems } from "src/slices/motor";
import { useDispatch, useSelector } from "src/store";
import useAsyncRequest from "./useAsyncRequest";

const motorUrls = {
  motor: {
    addDocument: `${server}/api/v2/motor/vehicle/addDocument/`,
    approve_inscription: `${server}/api/v2/motor/vehicle/approveInscription/`,
    end_inscription: `${server}/api/v2/motor/vehicle/endInscription/`,
    delete_inscription: `${server}/api/v2/motor/vehicle/deleteInscription/`,
    handle: `${server}/api/v2/motor/`,
    inscription: `${server}/api/v2/motor/vehicle/newInscription/`,
    exempt_inscription: `${server}/api/v2/motor/vehicle/inscription/exempt/`,
    updateStatement: `${server}/api/v2/motor/vehicle/updateStatement/`,
    resetStatement: `${server}/api/v2/motor/vehicle/resetStatement/`,
    inscription_detail: `${server}/api/v2/motor/vehicle/inscription/`,
    inscriptionByVehicle: `${server}/api/v2/motor/vehicle/inscriptionByVehicle/`,
    inscription_identity: `${server}/api/v2/motor/vehicle/inscription/identity/`,
    inscription_list: `${server}/api/v2/motor/vehicle/inscriptions/`,
    changePLate: `${server}/api/v2/motor/vehicle/changePlate/`,
    options: `${server}/api/v2/motor/vehicle/options/`,
    vehicle: `${server}/api/v2/motor/vehicle/`,
    vinDecode: `${server}/api/v2/motor/vehicle/vindecode/`,
    validateQR: `${server}/api/v2/motor/vehicle/validateQR/`,
    assignSticker: `${server}/api/v2/motor/vehicle/assignSticker/`,
    transferVehicle: `${server}/api/v2/motor/vehicle/transferVehicle/`,
  },
};

const useMotor = () => {
  const { t } = useTranslation();
  const { adminMode } = useSelector(
    (state: { settings: any }) => state.settings
  );
  const { currentCustomer } = useSelector(
    (state: { customers: any }) => state.customers
  );
  const { vehicleTypes, vehicleUses, inscriptions, motorFilters } = useSelector(
    (state: { motor: any }) => state.motor
  );
  const initialized = React.useRef(false);

  const { api } = useAsyncRequest();
  const dispatch = useDispatch();

  const loadModuleOptions = async () => {
    try {
      const response = await api(motorUrls.motor.handle, {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "value",
        action: "get_options",
      });

      if (response.data?.data) {
        dispatch(
          setMotorItems({
            vehicleTypes: response.data.data.types,
            vehicleUses: response.data.data.uses,
            motorInitialized: true,
          })
        );
      }
      return response.data;
    } catch (e) {
      return e;
    }
  };

  const loadInscriptions = async () => {
    var postData = {
      mode: adminMode ? "admin" : "user",
      customer_id: currentCustomer.id,
      paginatedBy: 25,
      page: 1,
    };
    if (motorFilters) {
      postData = { ...postData, ...motorFilters };
    }
    try {
      var res = await api(motorUrls.motor.inscription_list, postData);
      //   add res.data.data to inscriptions and ensure unique
      // clone inscriptions to mutate
      const inscriptionsClone = [...inscriptions];
      // loop through res.data.data
      res.data.data.forEach((inscription) => {
        // check if inscription exists in inscriptions
        const exists = inscriptionsClone.find((i) => i.id === inscription.id);
        // if not exists, push to inscriptions
        if (!exists) {
          inscriptionsClone.push(inscription);
        }
      });
      // set inscriptions to inscriptionsClone
      dispatch(setMotorItems({ inscriptions: inscriptionsClone }));

      //   dispatch(setMotorItems({ inscriptions: res.data.data }));
    } catch (e) {
      return e;
    }
  };

  const changePlate = async (data: {
    new_plate: string;
    apply_at: string;
    user_procedure_id: number;
    id: number;
  }) => {
    const response = await api(motorUrls.motor.changePLate, {
      customer_id: currentCustomer.id,
      ...data,
    });
    return response.data;
  };

  const endInscription = async (values) => {
    
    try {
      const response = await api(motorUrls.motor.end_inscription, {
        customer_id: currentCustomer.id,
        ...values,
      }, {}, t("Desincorporando vehículo"));
      if(response.status !== 200){
        throw new Error(response.data.message);
      }
      if(response.data.data){
        dispatch(setMotorItems({ inscription: response.data.data }));
      }
      return response.data;
    } catch (e) {
      return e;
    }
  }

  const validateQR = async (data) => {
    try {
      const response = await api(motorUrls.motor.validateQR, data);
      return response.data;
    } catch (e) {
      return e;
    }
  }

  const assignSticker = async (data: {
    serialID: string;    
    id: number;
  }) => {
    try {
      const response = await api(motorUrls.motor.assignSticker, {
        customer_id: currentCustomer.id,
        ...data,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }

  const vehicleTransfer = async (data: {
    serialID: string;    
    id: number;
  }) => {
    try {
      const response = await api(motorUrls.motor.transferVehicle, {
        customer_id: currentCustomer.id,
        ...data,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (
        !vehicleTypes ||
        !vehicleUses ||
        !vehicleTypes.length ||
        !vehicleUses.length
      ) {
        loadModuleOptions();
      }
    }
  }, []);

  

  return {
    changePlate,
    loadModuleOptions,
    loadInscriptions,
    vehicleInscription: {
      endInscription,
      vehicleTransfer,
    },
    sticker: {
      assignSticker,
      validateQR,
    },
  };
};

export default useMotor;
