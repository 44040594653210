import { server, ws_server } from "src/coreConfig";
import { setAIState } from "src/slices/ai";
import { useDispatch, useSelector } from "src/store";
import { handleDownloadFile } from "src/utils/dataRenders";
import useAsyncRequest from "./useAsyncRequest";
import useWebSocket from "./useWebSocket";
const apiPath = "/api/v2/ai";
const iaUrls = {
  ia: {
    chat: server + apiPath + "/chat/",
    generateContent: server + apiPath + "/generateContent/",
    getImageData: server + apiPath + "/getImageData/",
    getImageDataFile: server + apiPath + "/getImageDataFile/",
    downloadImageDataFile: server + apiPath + "/downloadImageDataFile/",
    getImageDataFiles: server + apiPath + "/getImageDataFiles/",
    clearChat: server + apiPath + "/clearChat/",
    ws: ws_server + "/ws/ai/",
  },
};

const useAI = () => {
  const { messages } = useSelector((state) => state.ai);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { api } = useAsyncRequest();
  const dispatch = useDispatch();
  const { wsOnline, lastMessage, sendWs } = useWebSocket(iaUrls.ia.ws);

  const generateContent = async (message, role) => {
    console.log("generateContent", message, role, messages);
    dispatch(
      setAIState({ messages: [{ message, from: "user" }, ...messages] })
    );

    try {
      const res = await api(iaUrls.ia.generateContent, {
        message,
        role,
        customer_id: currentCustomer.id,
      });

      console.log("generateContent", res.data.data, messages);
      dispatch(
        setAIState({
          iaData: res.data.data,
          messages: [
            {
              message: res.data.data,
              from: "ia",
            },
            ...messages,
          ],
        })
      );
      return { res: 1, message: "Content generated", data: res.data.data };
    } catch (error) {
      console.error("generateContent", error);
      return { res: 0, message: error.message };
    }
  };

  const chatMessage = async (message, role) => {
    dispatch(
      setAIState({ messages: [{ message, from: "user" }, ...messages] })
    );
    try {
      const res = await api(iaUrls.ia.chat, {
        message,
        role,
        customer_id: currentCustomer.id,
      });
      dispatch(
        setAIState({
          messages: [{ message: res.data.data, from: "ia" }, ...messages],
        })
      );
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };

  const clearChat = async () => {
    dispatch(setAIState({ messages: [] }));
    try {
      await api.post(iaUrls.ia.clearChat);
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };

  const setInitialMessage = async (message) => {
    try {
      dispatch(
        setAIState({
          messages: [
            {
              message: message,
              from: "ia",
            },
          ],
        })
      );
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };

  const getImageData = async (file, options) => {
    try {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("customer_id", currentCustomer?.id || 0);
      if (options) {
        for (var key in options) {
          formData.append(key, options[key]);
        }
      }
      const res = await api(
        iaUrls.ia.getImageData,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        "Obteniendo datos del comprobante"
      );
      return {
        res: 1,
        message: "ImageData data obtained",
        data: res.data.data,
      };
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };

  const downloadImageDataFile = async (fileId) => {
    try {
      const res = await api(
        iaUrls.ia.downloadImageDataFile,
        {
          id: fileId,
          customer_id: currentCustomer.id,
        },
        {
          responseType: "blob",
        },
        "Descargando comprobante"
      );
      handleDownloadFile(res);
    } catch (error) {
      console.error("downloadImageDataFile", error);
    }
  };
  return {
    generateContent,
    chatMessage,
    clearChat,
    setInitialMessage,
    getImageData,
    downloadImageDataFile,
    iaWs: { wsOnline, lastMessage, sendWs },
  };
};

export default useAI;
