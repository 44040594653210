
import { ExitToAppRounded } from "@mui/icons-material";
import { Avatar, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import ResponsiveButton from "src/components/ResponsiveButton";
import { media_url } from "src/coreConfig";
import { clearBank } from "src/slices/bank";
import { useDispatch } from "src/store";
const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    height: theme.spacing(15),
    width: theme.spacing(30),
    "& img": {
      objectFit: "contain",
    },
  },
}));
const BankServiceHeader = ({
  currentService,
  currentCustomer,
  currentApp,
  showAccessButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleServiceLogout = () => {
    dispatch(clearBank());
    if (currentService.urls && currentService.urls.logout) {
      window.location.replace(currentService.urls.logout);
    }
  };
  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      <Grid item>
        {" "}
        <Avatar
          src={media_url(currentApp.custom_icon)}
          variant="rounded"
          className={classes.largeAvatar}
        />
      </Grid>
      {/* <Grid item>
        <Typography variant="h4">{currentService.name}</Typography>
      </Grid> */}
      {showAccessButton && (
        <Grid item>
          <ResponsiveButton
            title={t("Salir")}
            color="success"
            variant="outlined"
            onClick={() => handleServiceLogout()}
            startIcon={<ExitToAppRounded />}
          >
            {t("Salir")}
          </ResponsiveButton>
        </Grid>
      )}
    </Grid>
  );
};

export default BankServiceHeader;
