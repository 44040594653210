import { createSlice } from "@reduxjs/toolkit";
import { default as http } from "axios";
import _ from "lodash";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  licenses: null,
  licensesCount: 0,
  licensesCopy: [],
  licenseProcedures: [],
  licenseCategories: [],
  liquorActivities: null,
  currentPenalty: null,
  currentPeriod: null,
  currentAdminPeriod: null,
  currentAdminPeriodDeclarations: null,
  currentAdminPeriodPending: null,
  currentCustomerClassifier: null,
  loadingDeclaration: false,
  currentLicense: null,
  currentLicenseAmendments: [],
  currentLicenseProcedures: [],
  pendingPeriods: [],
  declarations: [],
  pendingDefinitives: [],
  licenseNotes: [],
  currentAmendment: null,
  currentDeclaration: null,
  declarationFileTypes: null,
  taxesUpdating: {
    licenseActivities: false,
    declarations: false,
    penalties: false,
    license: false,
    period: false,
  },
  searchingLicenses: false,
  activities: [],
  informalActivities: [],
  currentInformalPermission: null,
  taxesError: null,
  isLoading: {
    licenses: false,
  },
  taxesLoaded: false,
  taxesServicesConnected: [],
  pages: [1],
  taxesFilters: {
    page: 0,
    sectorId: { value: "all", label: "all" },
    parishId: { value: "all", label: "all" },
    paginatedBy: 50,
    orderBy: { value: "-balance", label: "balance desc" },
    isActive: { value: "NULL", label: "all" },
    parish: "all",
    sectors: "all",
    economyGroup: "all",
    balance: "all",
  },
};
const slice = createSlice({
  name: "taxes",
  initialState,
  reducers: {
    setTaxesElement: (state, action) => {
      Object.keys(action.payload).map((i) => {
        state[i] = action.payload[i];
      });
    },
    setLicenses: (state, action) => {
      const { licenses, count, filters, pages } = action.payload;

      if (state.licenses && state.licenses.length > 0) {
        state.licensesCopy = state.licenses;
      }
      state.licenses = licenses ? _.map(licenses, (_l) => _l) : [];
      state.licensesCount = count;
      state.pages = pages;
      state.taxesLoaded = true;
    },
    setExtraData: (state, action) => {
      state.licensesCount = action.payload.licensesCount;
    },
    cancelSearch: (state, action) => {
      state.licenses = state.licensesCopy;
    },
    setActivities: (state, action) => {
      if (action.payload) {
        state.activities = _.reject(action.payload, (_a) => !_a.values);
      }
    },
    deleteCurrentAdminPeriodFee: (state, action) => {
      const { fee_id } = action.payload;
      state.currentAdminPeriod = {
        ...state.currentAdminPeriod,
        fees: _.reject(state.currentAdminPeriod.fees, { fee_id: fee_id }),
      };
    },
    setCurrentAdminPeriod: (state, action) => {
      state.currentAdminPeriod = action.payload;
    },
    updateTaxesFilters: (state, action) => {
      state.taxesFilters = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoaded: (state, action) => {
      const { loaded } = action.payload;
      state.loaded = loaded;
    },
    setCurrentLicense: (state, action) => {
      state.currentLicense = action.payload;
      state.currentLicenseAmendments = action.payload?.amendments || [];
    },
    setCurrentPenalty: (state, action) => {
      state.currentPenalty = action.payload;
    },
    setCurrentDeclaration: (state, action) => {
      var d = _.find(state.declarations, {
        id: parseInt(action.payload),
      });
      if (d) {
        state.currentDeclaration = d;
      } else {
        state.taxesError = "declaration does not exists";
        state.currentDeclaration = null;
      }
    },
    setCurrentDeclarationDetail: (state, action) => {
      const { data, license } = action.payload;

      state.currentDeclaration = data;
      if (license) {
        state.currentLicense = license;
      }
    },
    deleteCurrentDeclaration: (state, action) => {
      state.currentDeclaration = null;
    },
    setUpdatedTaxes: (state, action) => {
      const { licenses } = action.payload;
      state.licenses = licenses;
      state.licensesCount = licenses ? licenses.length : 0;
    },
    updateCurrentDeclaration: (state, action) => {
      state.currentDeclaration = action.payload;
      var declarations = _.map(state.declarations, (_d) => {
        if (_d.id === action.payload.id) {
          return action.payload;
        }
        return _d;
      });
      state.declarations = declarations;
      // state.currentLicense = {
      //   ...state.currentLicense,
      //   declarations: declarations,
      // };
    },
    updateCurrentLicenseAttr: (state, act) => {
      const { action, value } = act.payload;

      var l = { ...state.currentLicense };
      if (Object.keys(l).includes(action)) {
        l[action] = value;
        state.currentLicense = l;
      }
    },
    clearCurrentDeclaration: (state, action) => {
      state.currentDeclaration = null;
    },
    setTaxesError: (state, action) => {
      if (action.payload) {
        const { message } = action.payload;
        state.taxesError = message;
      } else {
        state.taxesError = null;
      }
    },
    setUpdating: (state, action) => {
      state.taxesUpdating = { ...state.taxesUpdating, ...action.payload };
    },
    addTaxesService: (state, action) => {
      const { customer } = action.payload;
      var exist = _.find(state.taxesServicesConnected, {
        customer: { id: customer.id },
      });
      if (exist) {
        state.taxesServicesConnected = _.map((_s) => {
          if (_s.id === customer.id) {
            return customer;
          }
          return _s;
        });
      } else {
        state.taxesServicesConnected = _.concat(state.taxesServicesConnected, [
          customer,
        ]);
      }
    },
    updateLicense: (state, action) => {
      state.currentLicense = action.payload;
      state.licenses = _.map(state.licenses, (_l) => {
        if (_l.id == action.payload.id) {
          return action.payload;
        }
        return _l;
      });
    },
    addLicense: (state, action) => {
      const l = action.payload;

      state.licenses = _.concat(l, state.licenses);
    },
    addCurrentLicenseActivities: (state, action) => {
      const activity = action.payload;
      state.currentLicense = {
        ...state.currentLicense,
        activities: _.concat(state.currentLicense.activities, activity),
      };
    },
    updateCurrentLicenseActivities: (state, action) => {
      const activity = action.payload;
      state.currentLicense = {
        ...state.currentLicense,
        activities: _.map(state.currentLicense.activities, (_a) => {
          if (_a.activity_id == activity.activity_id) {
            return activity;
          }
          return _a;
        }),
      };
    },
    updateCurrentDeclarationIncome: (state, action) => {
      const { id, activity_id, amount, tax } = action.payload;

      state.currentDeclaration = {
        ...state.currentDeclaration,
        incomes: _.map(state.currentDeclaration.incomes, (_i) => {
          if (_i.id === parseInt(id)) {
            return {
              ..._i,
              amount: amount,
              tax: tax,
            };
          }
          return _i;
        }),
      };
    },
    setLoadingDeclaration: (state, action) => {
      state.loadingDeclaration = action.payload;
    },
    setCurrentAdminPeriodPending: (state, action) => {
      state.currentAdminPeriodPending = action.payload;
    },
    setCurrentAdminPeriodDeclarations: (state, action) => {
      state.currentAdminPeriodDeclarations = action.payload;
    },
    setSearchingLicenses: (state, action) => {
      state.searchingLicenses = action.payload;
    },
  },
});
export const reducer = slice.reducer;

export const setTaxesElement = (data) => async (dispatch) => {
  dispatch(slice.actions.setTaxesElement(data));
};

export const setLicenses = (data) => async (dispatch) => {
  dispatch(slice.actions.setLicenses(data));
};
export const getTaxesIndex = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading({ licenses: true }));
  try {
    const response = await axios.post(arkadu.urls.v2.taxes.license.list, data);
    if (response.data && response.data.res === 1) {
      dispatch(slice.actions.setLicenses(response.data.data));
    }
    if (response.data.extra) {
      dispatch(slice.actions.setExtraData(response.data.extra));
    }
  } catch (e) {
    dispatch(slice.actions.setTaxesError(e.data.message));
  }
  dispatch(slice.actions.setIsLoading({ licenses: false }));
};

export const addLicense = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.taxes.license.add, data);
  dispatch(slice.actions.addLicense(response.data.data));
};
export const setUpdatedTaxes = (data) => async (dispatch) => {
  dispatch(slice.actions.setUpdatedTaxes(data));
};
export const deleteCurrentAdminPeriodFee = (data) => async (dispatch) => {
  dispatch(slice.actions.deleteCurrentAdminPeriodFee(data));
  var response = await axios.post(arkadu.urls.v2.taxes.period.deleteFee, data);
  dispatch(slice.actions.setCurrentAdminPeriod(response.data.data));
};
export const addPeriodFee = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.taxes.period.addFee, data);
  dispatch(slice.actions.setCurrentAdminPeriod(response.data.data));
};

export const transferLicense = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.taxes.license.transferToEmail,
    data
  );
  if (response.data.res === 1) {
    dispatch(slice.actions.updateLicense(response.data.data));
  }
};
export const updateLicense = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.taxes.license.change, data);
  dispatch(slice.actions.add(response.data.data));
};
export const assignLicense = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.taxes.license.assign, data);
  dispatch(slice.actions.updateLicense(response.data.data));
};
export const changeLicense = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCurrentLicenseAttr(data));

  // dispatch(slice.actions.updateLicense(response.data.data));
};

export const getLicenseDetail = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading(true));
  try {
    const response = await axios.post(
      arkadu.urls.v2.taxes.license.detail,
      data
    );
    console.log(response.data.data);
    dispatch(slice.actions.setCurrentLicense(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(slice.actions.setTaxesError(e));
  }
  dispatch(slice.actions.setIsLoading(false));
};

export const setCurrentPenalty = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentPenalty(data));
};
export const gotCurrentDeclarationUpdate = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCurrentDeclaration(data));
};
export const updateCurrentDeclaration = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.taxes.declaration.update,
    data
  );
  console.log(response.data);
  dispatch(slice.actions.updateCurrentDeclaration(response.data.data));
};
export const changeDeclarationState = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.taxes.declaration.state, data);
  dispatch(slice.actions.updateCurrentDeclaration(response.data.data));
};
export const deleteCurrentDeclaration = (data) => async (dispatch) => {
  try {
    var response = await axios.post(
      arkadu.urls.v2.taxes.declaration.delete,
      data
    );
    dispatch(slice.actions.setCurrentLicense(response.data.data));
    dispatch(slice.actions.deleteCurrentDeclaration());
  } catch (err) {
    console.log(err);
    dispatch(slice.actions.setTaxesError(err.data));
  }
};
export const setCurrentDeclaration = (id) => async (dispatch) => {
  dispatch(slice.actions.setCurrentDeclaration(id));
};
export const setUpdatedDeclaration = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentDeclarationDetail({ data: data }));
};
export const getDeclaration = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentDeclarationDetail({ data: null }));
  dispatch(slice.actions.setLoadingDeclaration(true));
  try {
    var response = await axios.post(
      arkadu.urls.v2.taxes.declaration.detail,
      data
    );
    dispatch(slice.actions.setCurrentDeclarationDetail(response.data));
    dispatch(slice.actions.setLoadingDeclaration(false));
  } catch (err) {
    console.log(err);
    dispatch(slice.actions.setLoadingDeclaration(false));
    dispatch(slice.actions.setTaxesError({ message: "no existe" }));
  }
};

export const clearCurrentDeclaration = () => async (dispatch) => {
  dispatch(slice.actions.clearCurrentDeclaration());
};
export const addTaxesService = (data) => async (dispatch) => {
  dispatch(slice.actions.addTaxesService(data));
};
export const setCurrentLicense = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentLicense(data));
};
export const setCurrentAdminPeriod = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentAdminPeriod(data));
};
export const clearTaxesError = () => async (dispatch) => {
  dispatch(slice.actions.setTaxesError(null));
};
export const getActivities = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.taxes.activity.classifier,
    data
  );
  dispatch(slice.actions.setActivities(response.data.data));
};
export const updateIncome = (data) => async (dispatch) => {
  // dispatch(slice.actions.updateCurrentDeclarationIncome(data));
  var response = await axios.post(
    arkadu.urls.v2.taxes.declaration.income,
    data
  );
  try {
    dispatch(slice.actions.updateCurrentDeclaration(response.data.data));
  } catch (err) {
    console.log(err);
  }
};
export const changeLicenseActivity = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCurrentLicenseActivities(data));
  var response = await axios.post(
    arkadu.urls.v2.taxes.license.changeActivity,
    data
  );
  console.log(response.data.data);
  dispatch(slice.actions.setCurrentLicense(response.data.data));
};
export const addLicenseActivity = (data) => async (dispatch) => {
  dispatch(slice.actions.setUpdating({ licenseActivities: true }));
  var response = await axios.post(
    arkadu.urls.v2.taxes.license.addActivity,
    data
  );
  dispatch(slice.actions.setCurrentLicense(response.data.data));
};
export const cancelSearch = (data) => async (dispatch) => {
  dispatch(slice.actions.cancelSearch(data));
};
export const searchLicense = (data) => async (dispatch) => {
  dispatch(slice.actions.setSearchingLicenses(true));
  try {
    var response = await axios.post(arkadu.urls.v2.taxes.license.search, data);
    dispatch(slice.actions.setLicenses(response.data.data));
    dispatch(slice.actions.setSearchingLicenses(false));
  } catch (err) {
    dispatch(slice.actions.setSearchingLicenses(false));
    console.log(err);
  }
};
export const newTaxesServiceAction = (data) => async (dispatch) => {
  switch (data.action) {
    case "classifier":
      http.get(data.url).then((response) => {
        localStorage.setItem(`classifier_${data.customer_id}`, response.data);
        dispatch(slice.actions.setActivities(response.data));
      });

      break;
    default:
      console.log("not taxes action implemented", data.action);
  }
};
export const loadCurrentAdminPeriodDeclarations =
  (data) => async (dispatch) => {
    var response = await axios.post(
      arkadu.urls.v2.taxes.declaration.list,
      data
    );
    dispatch(
      slice.actions.setCurrentAdminPeriodDeclarations(response.data.data)
    );
  };
export const loadCurrentAdminPeriodPending = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.taxes.period.pendingLicenses,
    data
  );
  dispatch(slice.actions.setCurrentAdminPeriodPending(response.data.data));
};
export const updateTaxesFilters = (data) => async (dispatch) => {
  dispatch(slice.actions.updateTaxesFilters(data));
};
