
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { setStampState } from "src/slices/stamp";
import { useDispatch, useSelector } from "src/store";
import { defaultErrorStates, defaultViewStates } from "src/utils/dataRenders";
import ErrorView from "src/views/errors/ErrorView";

const viewStates = {
  ...defaultViewStates,
};
const errorStates = [...defaultErrorStates];
const StampLayout = ({ children }) => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const { stampCategories, stamps, stampUses } = useSelector(
    (state) => state.stamp
  );
  const dispatch = useDispatch();
  const initialized = useRef(null);
  const history = useHistory();
  const { id } = useParams();
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (searchText) => { };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [state, setState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { api } = useAsyncRequest();

  const loadData = async () => {
    var res = await api(
      arkadu.urls.v2.stamp.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "category",
        action: "list",
      },
      {},
      null
    );
    if (res.data.res === 1) {
      setData(res.data.data);
      dispatch(setStampState({ stampCategories: res.data.data }));
    }
    var res = await api(
      arkadu.urls.v2.stamp.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "stamp",
        action: "list",
      },
      {},
      null
    );
    if (res.data.res === 1) {
      dispatch(setStampState({ stamps: res.data.data }));
    }
    var res = await api(
      arkadu.urls.v2.stamp.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "stamp_use",
        action: "list",
      },
      {},
      null
    );
    if (res.data.res === 1) {
      dispatch(setStampState({ stampUses: res.data.data }));
    }

    // try {
    //   var res = await axios.post(arkadu.urls.v2.stamp.handle, {
    //     customer_id: currentCustomer.id,
    //     mode: adminMode ? "admin" : "user",
    //     instance: "category",
    //     action: "list",
    //   });
    //   closeSnackbar(sb);
    //   if (res.data.res === 1) {
    //     setData(res.data.data);
    //     dispatch(setStampState({ stampCategories: res.data.data }));
    //   } else {
    //     setErrorMessage(res.data.message || "error");
    //     enqueueSnackbar(res.data.message || "error", { variant: "error" });
    //   }
    // } catch (e) {
    //   console.log(e);
    //   setErrorMessage((e.data && e.data.message) || e.statusText || "error");
    //   setState(viewStates.internalError);
    //   enqueueSnackbar((e.data && e.data.message) || e.statusText || "error", {
    //     variant: "error",
    //   });
    // }
  };

  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
      if (!stampCategories) {
        console.log("loading categories", stampCategories);
        loadData();
      }
      console.log("stamp_layout sss");
    }
  }, [stampCategories]);

  if (errorStates.includes(state)) {
    return <ErrorView message={errorMessage} />;
  }
  if (!currentCustomer) {
    return <LoadingScreen message={t("Cargando componentes")} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default StampLayout;
