import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { useSelector } from "src/store";
import { dateFormat, numberFormat } from "src/utils/dataRenders";

const ExchangeValues = ({ mode }) => {
  const initialized = useRef(null);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { api } = useAsyncRequest();
  const [values, setValues] = useState([]);
  const { t } = useTranslation();

  // const loadValues = async () => {
  //     var res = await api(arkadu.urls.v2.global.exchange.list, {
  //         currency: 'VES'
  //     });
  //     if (res.data.res === 1) {
  //         setValues(res.data.data);
  //     }
  // }
  // useEffect(() => {
  //     if (initialized.current === null) {
  //         initialized.current = true
  //         loadValues();
  //     };

  // }, []);

  if (mode === "banner") {
    return (
      <Tooltip
        title={`Fuente BCV al ${dateFormat(
          currentCustomer.currencies[0]?.updated || new Date().toISOString()
        )}`}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: {
              xs: "0.65rem",
              sm: "0.85rem",
              md: "1rem",
            },
          }}
        >
          {currentCustomer.currencies
            .filter((c) => c.code !== "PTR" && !c.is_default)
            .sort((a, b) => a.exchange_rate - b.exchange_rate)
            .map((currency, i) => {
              return (
                <React.Fragment key={currency.code}>
                  <strong>{currency.name}:</strong>{" "}
                  {numberFormat({ value: 1 / currency.exchange_rate })}{" "}
                  {i < currentCustomer.currencies.length - 3 ? " | " : ""}
                </React.Fragment>
              );
            })}
        </Typography>
      </Tooltip>
    );
  }
  if (currentCustomer.currencies && mode === "table") {
    return (
      <Card>
        <CardHeader title={`${t("Tipo de cambio (BCV)")} *`} />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Moneda</TableCell>
                <TableCell>Bs.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCustomer.currencies
                .filter((c) => c.code !== "PTR" && !c.is_default)
                .sort((a, b) => a.exchange_rate - b.exchange_rate)
                .map((currency) => {
                  return (
                    <TableRow key={currency.code}>
                      <TableCell>{currency.name}</TableCell>
                      <TableCell>
                        {numberFormat({ value: 1 / currency.exchange_rate })}
                      </TableCell>
                    </TableRow>
                  );
                })}

              <TableRow>
                <TableCell colSpan={2}>
                  <Typography
                    sx={{
                      fontSize: "0.65rem",
                      color: "gray",
                      fontStyle: "italic",
                    }}
                  >
                    *{t("Actualizado")}{" "}
                    {dateFormat(
                      currentCustomer.currencies[0]?.updated ||
                        new Date().toISOString()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
  return <></>;
};

ExchangeValues.propTypes = {
  mode: PropTypes.oneOf(["table", "banner"]),
};
ExchangeValues.defaultProps = {
  mode: "table",
};
export default ExchangeValues;
