import { ContentCopy } from "@mui/icons-material";
import {
  Avatar,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuList,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Label from "src/components/Label";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu, { media_url } from "src/coreConfig";
import { useDispatch, useSelector } from "src/store";
import axios from "src/utils/axios";

const AccountType = ({ accountType }) => {
  const { t } = useTranslation();
  const typeVerbose = {
    savings: {
      label: t("Ahorro"),
      color: "info",
    },
    checking: {
      label: t("Corriente"),
      color: "info",
    },
  };
  return (
    <Label color={typeVerbose[accountType]?.color ?? "info"}>
      {typeVerbose[accountType]?.label ?? accountType}
    </Label>
  );
};
const PaymentAccounts = () => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const initialized = useRef();
  const history = useHistory();
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (searchText) => {};
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const loadData = async () => {
    var res = await axios.post(arkadu.urls.v2);
    if (res.data.res === 1) {
      setData(res.data.data);
    } else {
      enqueueSnackbar(res.data.message || "error", { variant: "error" });
    }
  };

  useEffect(() => {
    if (initialized.current === undefined) {
      initialized.current = true;
    }
  }, []);
  if (!currentCustomer) {
    return <LoadingScreen message={t("Cargando componentes")} />;
  }

  if (isSmall) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              dense
              sx={{
                "& td": {
                  padding: "3px !important",
                  borderBottom: "none",
                  lineHeight: "1.2 !important",
                },
                "& > .MuiTableCell-root": {
                  padding: "3px !important",
                  lineHeight: "1.2 !important",
                },
              }}
            >
              <TableBody>
                {currentCustomer.accounts?.map((a) => {
                  if (!a.is_public || !a.transfers_enabled) return null;
                  return [
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{
                          padding: "3px !important",
                          verticalAlign: "middle",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          paddingTop: "10px !important",
                        }}
                      >
                        <Avatar
                          src={media_url(a.image)}
                          alt={a.name}
                          sx={{
                            width: {
                              xs: "40px",
                              sm: "60px",
                            },
                            height: {
                              xs: "40px",
                              sm: "60px",
                            },
                            display: "inline-block",
                            textAlign: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        />{" "}
                        <Typography
                          variant="body1"
                          sx={{ display: "inline-block", paddingLeft: "10px" }}
                        >
                          {" "}
                          {a.name}
                        </Typography>
                        {/* add icon to copy */}
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(`
                              ${a.account_number}\n
                              ${a.holders_name}\n
                              ${a.documentId}
                              `);

                            enqueueSnackbar(t("Número de cuenta copiado"), {
                              variant: "success",
                            });
                          }}
                          color={"primary"}
                        >
                          <ContentCopy />
                        </IconButton>
                      </TableCell>
                    </TableRow>,
                    <TableRow>
                      <TableCell>{a.holders_name}</TableCell>
                    </TableRow>,
                    <TableRow>
                      <TableCell>{a.account_number}</TableCell>
                    </TableRow>,
                    <TableRow>
                      <TableCell>{a.documentId}</TableCell>
                    </TableRow>,
                    <TableRow
                      sx={{
                        borderBottom: "1px solid #e0e0e0",
                        padding: "3px !important",
                        marginBottom: "10px",
                      }}
                    >
                      <TableCell>
                        <AccountType accountType={a.account_type} />
                      </TableCell>
                    </TableRow>,
                  ];
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MenuList dense={isSmall}>
          {currentCustomer.accounts?.map((a) => {
            if (!a.is_public || !a.transfers_enabled) return null;
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    src={media_url(a.image)}
                    sx={{
                      width: {
                        xs: "40px",
                        sm: "60px",
                      },
                      height: {
                        xs: "40px",
                        sm: "60px",
                      },
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ marginLeft: "18px" }}
                  primary={a.name}
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: "inline-block" }}>
                        {a.account_number}{" "}
                      </Typography>
                      <Typography
                        sx={{ display: "inline-block", paddingLeft: "10px" }}
                      >
                        <AccountType accountType={a.account_type} />
                      </Typography>
                      <Typography>{a.holders_name}</Typography>
                      <Typography>{a.documentId}</Typography>
                    </React.Fragment>
                  }
                />
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(`
                              ${a.account_number}\n
                              ${a.holders_name}\n
                              ${a.documentId}
                              `);

                    enqueueSnackbar(t("Número de cuenta copiado"), {
                      variant: "success",
                    });
                  }}
                  color={"primary"}
                >
                  <ContentCopy />
                </IconButton>
              </ListItem>
            );
          })}
        </MenuList>
      </Grid>

      {/* {currentCustomer.accounts ? (
        currentCustomer.accounts.map((a) => {
          if (!a.is_public) {
            return null;
          }

          return (
            <Grid item xs={12} md={6} lg={4} key={`${a.id}-pa`}>
            
              <ColorCard avatarImg={a.image} title={a.name}>
                <Table
                  sx={{
                    "& td": {
                      padding: "3px !important",
                      borderBottom: "none",
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">{t("Títular")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{a.holders_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Número")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{a.account_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Doc ID")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{a.documentId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Tipo")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          a.account_type === "checking"
                            ? "Corriente"
                            : a.account_type
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ColorCard>
            </Grid>
          );
        })
      ) : (
        <Typography>
          {t("No se encontraron cuentas para registrar pagos en este momento.")}
        </Typography>
      )} */}
    </Grid>
  );
};

export default PaymentAccounts;
