

import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomerCard from "src/components/Customers/CustomerCard";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu from "src/coreConfig";
import { loadSubscriptions } from "src/slices/premium";
import { useDispatch, useSelector } from "src/store";
import { dateFormat } from "src/utils/dataRenders";
import BasePage from "src/views/BasePage";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
  arkaduOffer: {
    backgroundImage: "url(/static/images/arkadu_business.jpg)",
    height: "250px",
    backgroundSize: "cover",
    backgroundRepeat: "not-repeat",
    padding: "20px",
  },
  arkaduOfferText: {
    backgroundColor: "#ffffffe0",
    padding: "20px",
    width: "350px",
  },
  customerCategory: {
    borderRadius: "10px",
    backgroundColor: "#476c8d",
    color: "white",
    marginBottom: "10px",
    padding: "10px",
  },
  bgPrimary: {
    backgroundColor: "#476c8d",
    color: "white",
  },
  bgSecondary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `white !important`,
  },
  bgTertiary: {
    backgroundColor: `${theme.palette.success.main} !important`,
    color: "white",
  },
  bgSuccess: {
    backgroundColor: `${theme.palette.success.main} !important`,
    color: "white",
  },
}));

function ModuleView() {
  const classes = useStyles();
  const history = useHistory();
  const [cusCategory, setCusCategory] = useState("");
  const [categorizedCustomers, setCategorizedCustomers] = useState();
  const { t } = useTranslation();
  const location = useLocation();
  const { customers } = useSelector((state) => state.customers);
  const bgs = ["bgPrimary", "bgSecondary", "bgTertiary", "bgSuccess"];
  const { user } = useSelector((state) => state.users);
  const { subscriptions, loading, loaded } = useSelector(
    (state) => state.premium
  );
  const dispatch = useDispatch();
  if (!customers || customers.length === 0) {
    return <LoadingScreen message={t("cargando cus")} />;
  }

  useEffect(() => {
    if (!window["APP_HOST"]) return;
    if (window["APP_HOST"].endsWith("arkadu.com") && !loaded) {
      dispatch(loadSubscriptions());
    }
  }, [arkadu.host, loaded]);

  if (
    !window["APP_HOST"]?.endsWith("arkadu.com") &&
    !window["APP_HOST"]?.endsWith("gestionciudad.com")
  ) {
    console.log("redirecting to", window["APP_HOME"]);
    return <Redirect to={window["APP_HOME"]} />;
  }

  return (
    <BasePage title={t("Inicio")}>
      {/* {user.companies && user.companies.length > 0 && (
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Typography variant="h4">{t('Mis Empresas')}</Typography>
          </Grid>
          {user.companies.map(c => {
            return (
              <Grid item sm={4} key={`c-${c.id}`}>
                <Typography variant="h5">{c.name}</Typography>
              </Grid>
            );
          })}
        </Grid>
      )} */}
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Card>
            <CardHeader title={t("Mi perfil")} />
            <CardContent>
              <Typography variant="h5">{user.fullname}</Typography>
              {/* <Typography variant="h6">{user.email}</Typography> */}
              <Typography variant="h6">
                {t("Registrado")} {dateFormat(user.created)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item sm={12}>
          <Card>
            <CardHeader title={t("")} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  sx={{ width: "180px", height: "180px" }}
                  onClick={() => {
                    window.open(
                      "https://alcaldialosguayos.org/losguayos/",
                      "_blank"
                    );
                  }}
                >
                  <img
                    src="https://arkadu.com/djmedia/customers/logo/logo_ORVJzuV.jpg"
                    alt="Alcaldía los guayos"
                    style={{ width: "160px", height: "160px" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item sm={12}>
          <Card>
            <CardHeader
              title={t("Mis suscripciones")}
              avatar={<CelebrationOutlined />}
            />
            <CardContent>
              <Grid spacing={3}>
                {loading && !loaded && (
                  <LoadingScreen message={t("cargando suscripciones")} />
                )}
                {subscriptions.length === 0 && (
                  <>
                    <Typography variant="h5">
                      {t("No tienes suscripciones activas")}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => {
                        history.push("/premium/");
                      }}
                    >
                      Comprar
                    </Button>
                  </>
                )}
                {subscriptions.map((c) => {
                  return (
                    <Grid item sm={4} key={`c-${c.id}`}>
                      <Typography variant="h5">{c.name}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid> */}

        {/* <div className={classes.arkaduOffer}>
            <div className={classes.arkaduOfferText}>
              <Typography variant="h5">
                Obtén 3 meses gratis en arkadu. ¡sin clausulas de permanencia!
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  history.push("/app/newOrganization/");
                }}
              >
                {t("Crear Organización")}
              </Button>
            </div>
          </div> */}

        {customers.map((c, k) => {
          if (c.customers && c.customers.length > 0) {
            return (
              <Grid item sm={12} md={6} key={`ca-${c.id}`}>
                <Grid
                  container
                  className={clsx(classes.customerCategory, classes[bgs[k]])}
                >
                  <Grid item sm={12}>
                    <Typography variant="h4">{c.name}</Typography>
                  </Grid>
                  {c.customers.map((cus) => {
                    if (!cus.is_private) {
                      return (
                        <Grid
                          item
                          sm={6}
                          md={4}
                          key={`cus-${cus.id}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              cus.custom_domain &&
                              window.location.hostname === "arkadu.com"
                            ) {
                              window.location.replace(
                                `https://${cus.custom_domain}/${cus.shortname}`
                              );
                            } else {
                              history.push(`/${cus.shortname}`);
                            }
                          }}
                        >
                          <CustomerCard currentCustomer={cus} />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
            );
          }
        })}
      </Grid>
    </BasePage>
  );
}

export default ModuleView;
