
import _ from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu from "src/coreConfig";
import useInterval from "src/hooks/useInterval";
import useWebSocket from "src/hooks/useWebSocket";
import {
  checkTrInProgress,
  getBankServices,
  getServiceProducts,
  setAccessExpired,
  setBankError,
  setBankOnline,
  setBankServices,
  setCurrentService,
  setServiceProducts,
} from "src/slices/bank";
import { useDispatch, useSelector } from "src/store";
import TransferConfirm from "./TransferConfirm";

const BankLayout = ({ children }) => {
  const {
    currentService,
    bankServices,
    bankErrors,
    currentTransfer,
    transferResult,
    accessExpired,
    bankOnline,
    serviceProducts,
    transferInProgress,
    loadingServices,
    loadingProducts,
  } = useSelector((state) => state.bank);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { t } = useTranslation();
  const { serviceId } = useParams();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const [lastDisplayedError, setLastDisplayedError] = useState(-1);
  const dispatch = useDispatch();
  const history = useHistory();
  const { ws, wsOnline, wsMessages, lastMessage } = useWebSocket(
    arkadu.urls.webSocket.bank
  );
  const [tokRefreshTime, setTokRefreshTime] = useState(60000);

  const refreshToken = () => {
    if (ws && ws.current && wsOnline && currentService) {
      ws.current.send(
        JSON.stringify({
          type: "bank_request",
          message: {
            type: "refresh_token",
            customer_id: currentCustomer.id,
            service_id: currentService.id,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (!currentService && serviceId && bankServices && bankServices.length) {
      dispatch(
        setCurrentService(_.find(bankServices, { id: parseInt(serviceId) }))
      );
    }
  }, [serviceId, currentService, bankServices]);

  useInterval(refreshToken, tokRefreshTime);

  useEffect(() => {
    dispatch(checkTrInProgress());
  }, []);

  useEffect(() => {
    if (transferResult) {
      history.push(
        `/${currentCustomer.shortname}/bank/transfer/result/${transferResult.id}/`,
        {
          result: transferResult,
        }
      );
    }
  }, [transferResult]);

  useEffect(() => {
    if (bankErrors && lastDisplayedError !== bankErrors.length - 1) {
      var m = bankErrors[bankErrors.length - 1];
      if (m === "login_required") {
        history.push(`/${currentCustomer.shortname}/bank/`);
      }
      enqueueSnackbar(m, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        preventDuplicate: false,
      });
      setLastDisplayedError(bankErrors.length - 1);
    }
  }, [bankErrors]);

  // useEffect(() => {
  //   if (bankOnline) {
  //     setTokRefreshTime(2500);
  //   } else {
  //     setTokRefreshTime(5000);
  //   }
  // }, [bankOnline]);

  useEffect(() => {
    if (!currentService && currentTransfer && bankServices) {
      var cS = _.find(bankServices, { id: currentTransfer.service.id });
      dispatch(setAccessExpired(false));
      dispatch(setCurrentService(cS));
    }
  }, [currentTransfer, currentService, bankServices]);

  useEffect(() => {
    if (lastMessage) {
      if (lastMessage.type === "service_list") {
        dispatch(setBankServices(lastMessage));
      }
      if (
        lastMessage.type === "service_error" ||
        lastMessage.type === "bank_error"
      ) {
        dispatch(setBankError(lastMessage.message));
      }
      if (lastMessage.type === "client_products") {
        dispatch(setServiceProducts(lastMessage.data));
      }
      if (lastMessage.status_code === 403) {
        dispatch(setBankOnline(false));
        history.push(`/${currentCustomer.shortname}/bank/`);
      }
    }
  }, [lastMessage]);

  // useEffect(() => {
  //   if (
  //     wsOnline &&
  //     ws &&
  //     ws.current &&
  //     currentService &&
  //     currentCustomer &&
  //     !serviceProducts
  //   ) {
  //     ws.current.send(
  //       JSON.stringify({
  //         type: 'bank_request',
  //         message: {
  //           type: 'get_service_products',
  //           customer_id: currentCustomer.id,
  //           service_id: currentService.id
  //         }
  //       })
  //     );
  //     // dispatch(
  //     //   getServiceProducts({
  //     //     url: currentService.urls.products,
  //     //     customer_id: currentCustomer.id,
  //     //     service_id: currentService.id
  //     //   })
  //     // );
  //   }
  //   if (serviceProducts && serviceProducts.length > 0) {
  //     dispatch(setBankOnline(true));
  //   } else {
  //     dispatch(setBankOnline(false));
  //   }
  // }, [ws, currentService, wsOnline, serviceProducts, currentCustomer]);

  // useEffect(() => {
  //   if (wsOnline && ws && ws.current && !bankServices && currentCustomer) {
  //     ws.current.send(
  //       JSON.stringify({
  //         type: 'bank_request',
  //         message: {
  //           type: 'get_service_list',
  //           customer_id: currentCustomer.id
  //         }
  //       })
  //     );
  //   }
  // }, [wsOnline, bankServices, currentCustomer]);

  useEffect(() => {
    if (!bankServices && currentCustomer) {
      dispatch(
        getBankServices({
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        })
      );
    }
  }, [bankServices, currentCustomer]);

  useEffect(() => {
    if (currentService && currentCustomer && !serviceProducts) {
      dispatch(
        getServiceProducts({
          url: currentService.urls.products,
          service_id: currentService.id,
          customer_id: currentCustomer.id,
        })
      );
    }
  }, [currentService, currentCustomer, serviceProducts]);

  useEffect(() => {
    if (accessExpired) {
      dispatch(setAccessExpired(false));
      if (!location.pathname.startsWith("/banesco/bank/transfer/result")) {
        history.push(`/${currentCustomer.shortname}/bank/`);
      }
    }
  }, [accessExpired]);

  if (loadingServices) {
    return <LoadingScreen message={t("cargando servicios")} />;
  }
  if (loadingProducts) {
    return <LoadingScreen message={t("cargando productos")} />;
  }
  if (transferInProgress) {
    return <TransferConfirm />;
  }
  return <>{children}</>;
};
BankLayout.propTypes = {
  children: PropTypes.node,
};
export default BankLayout;
