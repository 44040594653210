
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
const CustomDatePicker = ({
  id,
  name,
  onChange,
  value,
  label,
  format,
  minDate,
  maxDate,
  variant,
  disablePast,
  disableFuture,
  autoOk,
  disabled,
  inputVariant,
  error,
  helperText,
  locale,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [date, setDate] = React.useState(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
      <DatePicker
        value={date}
        disabled={disabled}
        minDate={disablePast ? new Date() : minDate}
        maxDate={disableFuture ? new Date() : maxDate}
        label={label}
        mask={"__/__/____"}
        renderInput={(params) => <TextField {...params} />}
        onChange={(e, kb) => {
          setDate(e);
          // check if e is a valid date
          if (e instanceof Date) {
            try {
              // set the date to the correct timezone and hours to 12
              e.setHours(12);
              const tzDate = new Date(
                new Date(e).toLocaleString("en-US", {
                  timeZone: "America/Caracas",
                })
              );
              // tzDate.setHours(12);

              onChange(e);
            } catch (error) {
              // console.log(error);
              // onChange(null);
              return;
            }
          } else {
            console.log("is not a date");
            // onChange(null);
          }

          // onChange(
          //   new Date(
          //     new Date(e).toLocaleString("en-US", {
          //       timeZone: "America/Caracas",
          //     })
          //   )
          // );
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

// minDate={undefined}
//                      maxDate={undefined}
//                      variant={undefined}
//                       disablePast={undefined}
//                        disableFuture={undefined}
//                         autoOk={undefined}
//                         disabled={undefined}

CustomDatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  format: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.any,
  ]),
  maxDate: PropTypes.instanceOf(Date),
  variant: PropTypes.string,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  autoOk: PropTypes.bool,
  disabled: PropTypes.bool,
  inputVariant: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  locale: PropTypes.string,
};
CustomDatePicker.defaultProps = {
  variant: "dialog",
  disablePast: false,
  disableFuture: false,
  inputVariant: "outlined",
  autoOk: true,
  value: moment().format("YYYY-MM-DD"),
  format: "YYYY-MM-DD",
  name: "custom-date-picker",
  id: "custom-date-picker",
};

export default CustomDatePicker;
